import type {Errors} from './errors';
import type {ReactNode} from 'react';

import translateOrFallback from '../i18n/translateOrFallback';
import ValidationError from '../validators/ValidationError';

import {ApolloError} from '@apollo/client';
import {GraphQLError} from 'graphql/error';
import {t} from 'i18next';

export type GraphQLErrorHandlerResult<T extends Record<string, unknown>> = [
  Errors<T>,
  Array<ReactNode>,
];
export type GraphQLErrorHandler<T extends Record<string, unknown>> = (
  err: GraphQLError,
) => GraphQLErrorHandlerResult<T>;

export default class FormSubmissionError<
  TInput extends Record<string, unknown>,
> extends Error {
  constructor(
    private fieldErrors: Errors<TInput>,
    private formErrors: Array<React.ReactNode>,
  ) {
    super();
  }

  getFieldErrors(): Errors<TInput> {
    return this.fieldErrors;
  }

  getFormErrors(): Array<React.ReactNode> {
    return this.formErrors;
  }

  static fromGraphQLMutationError<T extends Record<string, unknown>>(
    err: unknown,
    handleGraphQLError: GraphQLErrorHandler<T>,
  ): FormSubmissionError<T> {
    if (err instanceof ApolloError) {
      let fieldErrors: Errors<T> = {};
      let formErrors: Array<ReactNode> = [];
      // If there are graphql errors, ignore network error
      if (err.graphQLErrors.length > 0) {
        for (const error of err.graphQLErrors) {
          const [newFieldErrors, newFormErrors] = handleGraphQLError(error);
          fieldErrors = {
            ...newFieldErrors,
            ...fieldErrors,
          };
          formErrors = formErrors.concat(newFormErrors);
        }
        return new FormSubmissionError<T>(fieldErrors, formErrors);
      } else {
        return new FormSubmissionError({}, [
          translateOrFallback(
            err.message,
            t('form.unknown-form-submission-error'),
          ),
        ]);
      }
    } else if (err instanceof FormSubmissionError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return err;
    } else if (err instanceof ValidationError) {
      return new FormSubmissionError<T>({}, [err.getMessage()]);
    } else {
      return new FormSubmissionError<T>({}, [
        t('form.unknown-form-submission-error'),
      ]);
    }
  }
}
