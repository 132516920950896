import useAppTheme from '../hooks/useAppTheme';
import {createPurePalette} from '../theme/palettes';
import {FontFamily} from '../typography/options';

import {CSSInterpolation} from '@emotion/serialize';

export interface Props {
  iconCount?: number;
  isDisabled?: boolean | null;
  isInvalid?: boolean | null;
}

export default function useInputCSS(props: Props): CSSInterpolation {
  const {isDisabled, isInvalid} = props;
  const theme = useAppTheme();
  let borderPalette = theme.palettes.background.neutralSubdued;
  if (isInvalid) {
    borderPalette = theme.palettes.content.negative;
  } else if (isDisabled) {
    borderPalette = createPurePalette(
      theme.palettes.background.neutralSubdued.disabled,
    );
  }

  return {
    fontFamily: FontFamily.NOTO_SANS_JS,
    border: `2px solid ${borderPalette.default}`,
    borderRadius: 6,
    boxSizing: 'border-box',
    color: theme.palettes.content.neutral.default,
    height: 32,
    paddingLeft: theme.spacing.x12,
    width: '100%',

    '&:hover': {
      borderColor: borderPalette.hover,
    },

    '&:focus-visible': {
      borderColor: borderPalette.active,
      outline: `1.5px solid ${theme.palettes.background.accent.focus}`,
      outlineOffset: 2,
    },

    '&::placeholder': {
      color: theme.palettes.content.neutral.default,
    },
  };
}
