import I18nError from '../errors/I18nError';

export default class ValidationError extends I18nError {
  constructor(message: string) {
    super(message);
  }

  getMessage(): string {
    return this.message;
  }
}
