import {AppIconProps} from './AppIconProps';
import useFeatherIconProps from './useFeatherIconProps';

import React from 'react';
import {AlertTriangle as FeatherAlertTriangle} from 'react-feather';

export default function AlertTriangle(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);

  return <FeatherAlertTriangle {...iconProps} />;
}