import useInputCSS from './useInputCSS';
import {CSSStyles} from '../../../types/CSSStyles';
import useAppTheme from '../hooks/useAppTheme';
import AlertTriangle from '../icon/AlertTriangle';
import Check from '../icon/Check';
import ChevronDown from '../icon/ChevronDown';
import {ContentColor} from '../theme/useContentPalette';

import {css} from '@emotion/react';
import React from 'react';

interface SharedProps {
  readonly inputSX?: CSSStyles;
  readonly isInvalid?: boolean;
  // When set to true, an icon indicating valid value will be shown when isInvalid is false
  readonly showValidIcon?: boolean;
}

type OmitKeys = 'sx';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, OmitKeys>,
    SharedProps {
  readonly variant: 'input';
}

export interface TextareaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, OmitKeys>,
    SharedProps {
  readonly variant: 'textarea';
}

export interface SelectProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, OmitKeys>,
    SharedProps {
  readonly multiple: false;
  readonly variant: 'select';
  readonly children?: React.ReactNode;
}

export interface MultiSelectProps
  extends Omit<React.SelectHTMLAttributes<HTMLDivElement>, OmitKeys>,
    SharedProps {
  readonly multiple: true;
  readonly variant: 'select';
  readonly children?: React.ReactNode;
  readonly name?: string;
}

export type Props = InputProps | TextareaProps | SelectProps | MultiSelectProps;

export default function Input(props: Props): JSX.Element {
  const {inputSX, isInvalid, showValidIcon, ...htmlProps} = props;
  const {disabled, variant} = htmlProps;
  const theme = useAppTheme();
  const {palettes, spacing} = theme;
  const inputCSS = useInputCSS({
    isDisabled: disabled,
    isInvalid,
  });
  let validityIcon: React.ReactNode = null;
  if (isInvalid) {
    validityIcon = (
      <AlertTriangle
        color={ContentColor.ERROR}
        size={20}
        strokeWidth={2}
      />
    );
  } else if (showValidIcon) {
    validityIcon = (
      <Check
        color={ContentColor.SUCCESS}
        size={20}
        strokeWidth={2}
      />
    );
  }
  let element;
  switch (variant) {
    case 'input':
      element = (
        <>
          <input
            css={[
              css(inputCSS),
              validityIcon != null &&
                css({
                  paddingRight: 28,
                }),
              inputSX,
            ]}
            {...htmlProps}
          />
          <IconWrapper>{validityIcon}</IconWrapper>
        </>
      );
      break;
    case 'textarea':
      element = (
        <textarea
          css={[
            css(inputCSS),
            css({
              height: 'auto',
              wdith: 'auto',
              fontSize: '16px',
              lineHeight: '2em',
            }),
            inputSX,
          ]}
          {...htmlProps}
        />
      );
      break;
    case 'select':
      if (htmlProps.multiple) {
        const {multiple: _, ...selectProps} = htmlProps;
        element = (
          <div
            css={[
              css({
                border: `2px solid ${palettes.background.neutralSubdued.default}`,
                overflowY: 'auto',
                overflowX: 'hidden',
                height: '100%',
                width: '100%',

                '& option': {
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: spacing.x12,
                  color: palettes.content.neutral.default,

                  '&:checked': {
                    color: palettes.background.contrast.neutral.default,
                    boxShadow: `0 0 10px 100px ${palettes.background.neutral.default} inset`,
                  },
                },
              }),
              inputSX,
            ]}
            {...selectProps}
          />
        );
      } else {
        element = (
          <>
            <select
              css={[
                css(inputCSS),
                validityIcon == null &&
                  css({
                    paddingRight: 28,
                  }),
                validityIcon != null &&
                  css({
                    paddingRight: 53,
                  }),
                inputSX,
              ]}
              {...htmlProps}
            />
            <IconWrapper>
              {validityIcon}
              <div
                css={css({
                  alignItems: 'center',
                  backgroundColor:
                    theme.palettes.background.neutralSubdued.default,
                  borderRadius: '4px',
                  color: theme.palettes.background.neutral.default,
                  display: 'flex',
                  height: 20,
                  justifyContent: 'center',
                  marginLeft: theme.spacing.x6,
                  width: 20,
                })}
              >
                <ChevronDown size={14} />
              </div>
            </IconWrapper>
          </>
        );
      }
  }

  return (
    <div
      css={css({
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
        width: '100%',
      })}
    >
      {element}
    </div>
  );
}

function IconWrapper({children}: {children: React.ReactNode}): JSX.Element {
  return (
    <div
      css={css({
        alignItems: 'center',
        display: 'flex',
        pointerEvents: 'none',
        position: 'absolute',
        right: 6,
      })}
    >
      {children}
    </div>
  );
}
